import * as types from "./actionTypes";

const initialState = {
  loading: false,
  subjectList: null,
  subjectListData: [],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  subjectLessonList: [],
};

const SubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SUBJECT_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.SUBJECT_LIST_SUCCESS:
      // console.log("payload subect", action.payload);
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        subjectList: action.payload,
        //subjectListData: [...state.subjectListData, ...action.payload.data],
      };

    case types.SUBJECT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        subjectList: null,
        subjectListData: [],
        isError: true,
      };

    case types.CREATE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
      };

    case types.CREATE_SUBJECT_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };

    case types.SUBJECT_LESSON_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SUBJECT_LESSON_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.SUBJECT_LESSON_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        subjectLessonList: action.payload,
      };

    case types.SUBJECT_LESSON_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        subjectLessonList: null,
        isError: true,
      };

    default:
      return state;
  }
};

export default SubjectReducer;
