import toast from "react-hot-toast";
import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getLessonListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());

    const requestType = request?.isRefresh
      ? types.Lesson_LIST_REFRESH_REQUEST
      : types.Lesson_LIST_REQUEST;

    dispatch({ type: requestType });

    try {
      const response = await ApiList.getLessonList(request);
      if (response.status == 200) {
        dispatch({ type: types.Lesson_LIST_SUCCESS, payload: response.data });
      } else {
        dispatch({ type: types.Lesson_LIST_ERROR, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.Lesson_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const createLessonAction = (
  request,
  onStepChange,
  setIsStep1Complete
) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.CREATE_LESSON_REQUEST });

      const response = await ApiList.createLesson(request);
      if (response) {
        dispatch({ type: types.CREATE_LESSON_SUCCESS, payload: response });
        if (onStepChange) onStepChange();
        if (setIsStep1Complete) setIsStep1Complete(true);
      } else {
        dispatch({ type: types.CREATE_LESSON_ERROR, payload: response });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.CREATE_LESSON_SUCCESS, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const updateLessonSettingAction = ({
  request,
  uuid,
  onStepChange,
  setIsStep2Complete,
}) => {
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.UPDATE_LESSON_SETTING_REQUEST });

      const response = await ApiList.updateLessonSetting(request, uuid);
      // console.log("response createCourse", response);
      if (response) {
        dispatch({
          type: types.UPDATE_LESSON_SETTING_SUCCESS,
          payload: response,
        });
		if(onStepChange)onStepChange()
		if(setIsStep2Complete)setIsStep2Complete(true)	
      } else {
        dispatch({
          type: types.UPDATE_LESSON_SETTING_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.UPDATE_LESSON_SETTING_SUCCESS, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const lessonAssignToCourseAction = ({
  request,
  uuid,
  navigate
}) => {
  // console.log("update course setting", request);
  return async (dispatch) => {
    try {
      dispatch(apiLoadingStart());
      dispatch({ type: types.LESSON_ASSIGN_TO_COURSE_REQUEST });

      const response = await ApiList.lessonAssignToCourse(request, uuid);
      // console.log("response createCourse", response);
      if (response) {
        dispatch({
          type: types.LESSON_ASSIGN_TO_COURSE_SUCCESS,
          payload: response,
        });

        navigate('/lesson')
        toast.success("Lesson Created Successfully")
		  
      } else {
        dispatch({
          type: types.LESSON_ASSIGN_TO_COURSE_ERROR,
          payload: response,
        });
        setTimeout(() => AlertHelper.warning(response), 100);
      }
    } catch (error) {
      dispatch({ type: types.LESSON_ASSIGN_TO_COURSE_SUCCESS, payload: error });
      setTimeout(() => AlertHelper.warning(error), 100);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
