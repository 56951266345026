import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import global from './global';
import user from './user';
// import notification from './notification';
import course from './course';
import subject from './subject';
import lesson from './lesson'
import UserManagement from './userManagement';

const config = {
    key: 'root',
    debug: false,
    storage
};

const AppReducers = combineReducers({
    global,
    user,
    course,
    subject,
    lesson,
    UserManagement
});

const rootReducer = (state:any, action:any) => {
    return AppReducers(state, action);
};

const pReducer = persistReducer(config, rootReducer);

export default pReducer;
