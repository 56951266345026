import React from 'react';
import ReactDOM from 'react-dom/client';
import Navigation from "./navigation/index";
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react"; // Use this for React.js
// import { configureStore } from './src/Store'; // Your Redux store configuration
import { configureStore } from './Store/index';
import toast, { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();
const persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null}  persistor={persistor}>
   <Navigation/>  
   </PersistGate>
   </Provider>
   <Toaster />  
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
