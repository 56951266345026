export const RESET_STORE_PRODUCT = 'RESET_STORE_PRODUCT';


export const Lesson_LIST_SUCCESS ='Lesson_LIST_SUCCESS';
export const Lesson_LIST_REFRESH_REQUEST ='Lesson_LIST_REFRESH_REQUEST';
export const Lesson_LIST_REQUEST = 'Lesson_LIST_REQUEST';
export const Lesson_LIST_ERROR = 'Lesson_LIST_ERROR';

export const CREATE_LESSON_REQUEST ='CREATE_LESSON_REQUEST';
export const CREATE_LESSON_SUCCESS = 'CREATE_LESSON_SUCCESS';
export const CREATE_LESSON_ERROR = 'CREATE_LESSON_ERROR';

export const UPDATE_LESSON_SETTING_REQUEST ='UPDATE_LESSON_SETTING_REQUEST';
export const UPDATE_LESSON_SETTING_SUCCESS = 'UPDATE_LESSON_SETTING_SUCCESS';
export const UPDATE_LESSON_SETTING_ERROR = 'UPDATE_LESSON_SETTING_ERROR';


export const LESSON_ASSIGN_TO_COURSE_REQUEST ='LESSON_ASSIGN_TO_COURSE_REQUEST';
export const LESSON_ASSIGN_TO_COURSE_SUCCESS = 'LESSON_ASSIGN_TO_COURSE_SUCCESS';
export const LESSON_ASSIGN_TO_COURSE_ERROR = 'LESSON_ASSIGN_TO_COURSE_ERROR';






