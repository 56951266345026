import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getAllUserListAction = (request) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ALL_USER_LIST_REQUEST });

    try {
      const response = await ApiList.getAllUser(request);
      // console.log("response user", response);
      if (response) {
        dispatch({ type: types.ALL_USER_LIST_SUCCESS, payload: response });
      } else {
        dispatch({ type: types.ALL_USER_LIST_ERROR, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ALL_USER_LIST_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};

export const assignCourseToStudentAction = (request,navigate) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.ASSIGN_COURSE_TO_USER_REQUEST });

    try {
      const response = await ApiList.assignCourseToStudent(request);
      // console.log("response user", response);
      if (response.status) {
        dispatch({ type: types.ASSIGN_COURSE_TO_USER_SUCCESS, payload: response });
        navigate("/user-management")
      } else {
        dispatch({ type: types.ASSIGN_COURSE_TO_USER_SUCCESS, payload: response.message });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.ASSIGN_COURSE_TO_USER_SUCCESS,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
