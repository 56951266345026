const TAG = 'GLOB_'
export const API_LOADING_START = TAG + 'API_LOADING_START';
export const API_LOADING_STOP = TAG + 'API_LOADING_STOP';
export const IS_INTERNET_CONNECTED = TAG + 'IS_INTERNET_CONNECTED';
export const ON_ERROR_RECEIVED = TAG + 'ON_ERROR_RECEIVED';
export const CONFIG_SUCCESS = TAG + 'CONFIG_SUCCESS';
export const CONFIG_ERROR = TAG + 'CONFIG_ERROR';
export const SET_CURRENT_ROUTE_NAME = 'SET_CURRENT_ROUTE_NAME';

export const SET_CURRENT_LANGUAGE_REQUEST ='SET_CURRENT_LANGUAGE_REQUEST';
export const SET_CURRENT_LANGUAGE ='SET_CURRENT_LANGUAGE';

export const TRAS_REQUESTED = 'TRAS_REQUESTED';
export const TRAS_SUCCESS = 'TRAS_SUCCESS';
export const TRAS_FAILURE = 'TRAS_FAILURE';

export const APP_MINIMIZED = 'APP_MINIMIZED';



