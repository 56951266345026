import * as types from "./actionTypes";

const initialState = {
  loading: false,
  lessonList: null,
  lessonListData: [],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  lessonData: null,
  lessonId: null,
};

const LessonReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.Lesson_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.Lesson_LIST_REFRESH_REQUEST:
      return {
        ...state,
        loading: true,
        isRefrash: true,
      };

    case types.Lesson_LIST_SUCCESS:
      // console.log("payload", action.payload.data.data);
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        lessonList: action.payload.data.data,
        lessonData: action.payload.data,
        //lessonListData: [...state.lessonListData, ...action.payload.data],
      };

    case types.Lesson_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        lessonList: null,
        lessonListData: [],
        isError: true,
      };
    case types.CREATE_LESSON_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.CREATE_LESSON_SUCCESS:
      // console.log("payload", action.payload.data.data);
      return {
        ...state,
        loading: false,
        isError: false,
        lessonId: action.payload.data,
      };

    case types.CREATE_LESSON_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case types.UPDATE_LESSON_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
        isError: false,
      };

    case types.UPDATE_LESSON_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
      };

    case types.UPDATE_LESSON_SETTING_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
      };

    default:
      return state;
  }
};

export default LessonReducer;
