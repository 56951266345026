import * as types from './actionTypes';

const initialState = {
	loading: false,
	translation: {},
	isTranslation: false,
	isInternetConnected: true,
	errorMessage: '',
	configData: null,
	areaList: null,
	isError: false,
	currentRouteName: '',
	isDeviceSupportBiometrick: 0,
	currentLanguage:'en',
	currentAppRoute: ''
};
const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.API_LOADING_START:
			return {
				...state,
				loading: true
			};
		case types.API_LOADING_STOP:
			return {
				...state,
				loading: false
			};
		case types.IS_INTERNET_CONNECTED:
			if (action.payload === false) {
				return {
					...state,
					isInternetConnected: action.payload,
					loading: false
				};
			} else {
				return {
					...state,
					isInternetConnected: action.payload
				};
			}
		case types.ON_ERROR_RECEIVED:
			return {
				...state,
				loading: false,
				errorMessage: action.payload.message,
				isError: action.payload.type
			};
		case types.CONFIG_SUCCESS:
			return {
				...state,
				configData: { ...action.payload },
			};
		case types.CONFIG_ERROR:
			return {
				...state,
				message: action.payload,
			};
		case types.SET_CURRENT_ROUTE_NAME:
			return {
				...state,
				currentRouteName: action.payload,
			};
		case types.TRAS_REQUESTED:
			return {
				...state,
				isTranslation: true,
			};
		case types.TRAS_FAILURE:
			return {
				...state,
				translation: action.payload,
				isTranslation: false,
			};
		case types.TRAS_SUCCESS:
			return {
				...state,
				translation: action.payload,
				isTranslation: false,
			};

			case types.SET_CURRENT_LANGUAGE_REQUEST:
			return {
				...state,
				loading: true,
				// currentLanguage: action.payload,
			};
		case types.SET_CURRENT_LANGUAGE:
			return {
				...state,
				loading: false,
				currentLanguage: action.payload,
			};

		case types.APP_MINIMIZED:
			return {
				...state,
				loading: false,
				currentAppRoute: action.payload,
			};
		default:
			return state
	}
};

export default globalReducer

