import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { useDispatch } from "react-redux";
import { getProfileAction } from "../user";
import { useNavigate } from "react-router-dom";
export const apiLoadingStart = () => ({ type: types.API_LOADING_START });
export const apiLoadingStop = () => ({ type: types.API_LOADING_STOP });
export const noInternetConnected = (isConnected) => ({
  type: types.IS_INTERNET_CONNECTED,
  payload: isConnected,
});

export const setRedirect = (navigate) => {
  return async (dispatch) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken || accessToken === "undefined") {
        navigate("/signin"); // Navigate to the sign-in page if the token is not found
      } else {
        dispatch(getProfileAction(navigate)); // Dispatch the profile action if the token exists
      }
    } catch (err) {
      //console.error('Error in setRedirect:', err);
    }
  };
};
