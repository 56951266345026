const TAG = 'USER_'
export const RESET_STORE_USER = 'RESET_STORE_USER';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';

export const CHECK_NUMBER_REQUEST = 'CHECK_NUMBER_REQUEST';
export const CHECK_NUMBER_SUCCESS = 'CHECK_NUMBER_SUCCESS';
export const CHECK_NUMBER_ERROR = 'CHECK_NUMBER_ERROR';



export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const SESSION_USER_REQUEST = 'SESSION_USER_REQUEST';
export const SESSION_USER_SUCCESS = 'SESSION_USER_SUCCESS';
export const SESSION_USER_SUCCESS2 = 'SESSION_USER_SUCCESS2';
export const SESSION_USER_ERROR = 'SESSION_USER_ERROR';


export const SINGUP_USER_REQUEST = 'SINGUP_USER_REQUEST';
export const SINGUP_USER_SUCCESS =  'SINGUP_USER_SUCCESS';
export const SINGUP_USER_ERROR = 'SINGUP_USER_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_USER_REQUEST';
export const FORGOT_PASSWORD_SUCCESS =  'FORGOT_PASSWORD_USER_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_USER_ERROR';

export const CUSTOMER_ADDRESS_SUCCESS = 'CUSTOMER_ADDRESS_SUCCESS';
export const CUSTOMER_ADDRESS_ERROR = 'CUSTOMER_ADDRESS_ERROR';

export const UPDATEPROFILE_REQUEST = 'UPDATEPROFILE_REQUEST';
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS';
export const UPDATEPROFILE_ERROR = 'UPDATEPROFILE_ERROR';
export const GETPROFILE_REQUEST = 'GETPROFILE_REQUEST';
export const GETPROFILE_SUCCESS = 'GETPROFILE_SUCCESS';
export const GETPROFILE_ERROR = 'GETPROFILE_ERROR';

export const CONTACTUS_REQUEST = 'CONTACTUS_REQUEST';
export const CONTACTUS_SUCCESS =  'CONTACTUS_SUCCESS';
export const CONTACTUS_ERROR = 'CONTACTUS_ERROR';

export const MYPURCHASE_REQUEST = 'MYPURCHASE_REQUEST';
export const MYPURCHASE_SUCCESS =  'MYPURCHASE_SUCCESS';
export const MYPURCHASE_ERROR = 'MYPURCHASE_ERROR';

export const MYCERTIFICATE_REQUEST = 'MYCERTIFICATE_REQUEST';
export const MYCERTIFICATE_SUCCESS =  'MYCERTIFICATE_SUCCESS';
export const MYCERTIFICATE_ERROR = 'MYCERTIFICATE_ERROR';


export const UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS';
export const UPDATE_USER_LANGUAGE_ERROR = 'UPDATE_USER_LANGUAGE_ERROR';

export const CHECK_LOGIN_REQUEST = 'CHECK_LOGIN_REQUEST';
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS';
export const CHECK_LOGIN_ERROR = 'CHECK_LOGIN_ERROR';


export const GLOBAL_LOGOUT_REQUEST = 'GLOBAL_LOGOUT_REQUEST';
export const GLOBAL_LOGOUT_SUCCESS = 'GLOBAL_LOGOUT_SUCCESS';
export const GLOBAL_LOGOUT_ERROR = 'GLOBAL_LOGOUT_ERROR';

export const CLASSESDATA_REQUEST = 'CLASSESDATA_REQUEST';
export const CLASSESDATA_SUCCESS = 'CLASSESDATA_SUCCESS';
export const CLASSESDATA_ERROR = 'CLASSESDATA_ERROR';

export const STREAMSDATA_REQUEST = 'STREAMSDATA_REQUEST';
export const STREAMSDATA_SUCCESS = 'STREAMSDATA_SUCCESS';
export const STREAMSDATA_ERROR = 'STREAMSDATA_ERROR';





