import * as types from './actionTypes'

const initialState = {
	loading: false,
	loadingLogin: false,
	userData: null,
	contactUsList: {},
	myPurchaseList: {},
	myCertificateList: {},
	isLogin:false,
	getUserID: '',
	checkPhoneId:'',
	registration: '',
	isRefresh: false,
	otpLoading: false,
	webLoginCheck : "",
	classesData : [],
	streamsData : [],
	globalDoubtnetAttempts: [],
	sessionID: null,
	sessionCheck:null
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.RESET_STORE_USER:
			return initialState;
			
		case types.LOGIN_USER_REQUEST:
			return {
				...state,
				loading:true,
				loadingLogin: true,
			};
		case types.LOGIN_USER_SUCCESS:
			return {
				...state,
				loading:false,
				loadingLogin: false,
				isLogin:true
			};
		case types.LOGIN_USER_ERROR:
			return {
				...state,
				loading:false,
				loadingLogin: false,
				isLogin:false

			};
		case types.GETPROFILE_REQUEST:
			return {
				...state,
				loading: true
			}	
			
		case types.GETPROFILE_SUCCESS:
			return {
				...state,
				userData: action.payload,
				loading:false
			};
		case types.GETPROFILE_ERROR:
			return {
				...state,
				loading:false
			};

		// case types.SEND_OTP_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 		loadingLogin: true,
		// 		otpLoading: true,
		// 	};
		// case types.SEND_OTP_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading:false,
		// 		loadingLogin: false,
		// 		isLogin:true,
		// 		getUserID:action.payload,
		// 		otpLoading: false,
		// 	};
		// case types.SEND_OTP_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false,
		// 		loadingLogin: false,
		// 		isLogin:false,
		// 		otpLoading: false,

		// 	};
		// 	///check ohone
		// 	case types.CHECK_NUMBER_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 		loadingLogin: true,
		// 	};
		// case types.CHECK_NUMBER_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading:false,
		// 		loadingLogin: false,
		// 		isLogin:true,
		// 		checkPhoneId:action.payload,
		// 	};
		// case types.CHECK_NUMBER_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false,
		// 		loadingLogin: false,
		// 		isLogin:false

		// 	};
		// case types.SINGUP_USER_REQUEST:
		// 		return {
		// 			...state,
		// 			loading:true,
		// 		};
		// case types.SINGUP_USER_SUCCESS:
		// 		return {
		// 			...state,
		// 			loading:false,
		// 			registration: action.payload
		// 		};
		// case types.SINGUP_USER_ERROR:
		// 		return {
		// 			...state,
		// 			loading:false
		// 		};
		// 		// seesion match start
		// 		case types.SESSION_USER_REQUEST:
		// 		return {
		// 			...state,
		// 			loading:true,
		// 		};
		// 		case types.SESSION_USER_SUCCESS:
		// 				return {
		// 					...state,
		// 					loading:false,
		// 					sessionID: null,
		// 					sessionCheck: action.payload.data,
		// 				};
		// 		case types.SESSION_USER_SUCCESS2:
		// 				return {
		// 					...state,
		// 					loading:false,
		// 					sessionID: action.payload.device_id,
		// 					sessionCheck: null,
		// 				};
		// 		case types.SESSION_USER_ERROR:
		// 				return {
		// 					...state,
		// 					loading:false
		// 				};
		// 		// session match end
		// case types.LOGOUT_USER_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 	};
		// case types.LOGOUT_USER_SUCCESS:
		// 	return {
		// 		...state,
		// 		userData: null,
		// 		loading:false,
		// 		isLogin:false,
		// 		webLoginCheck: ''


		// 	};
		// case types.LOGOUT_USER_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};
	
		// case types.FORGOT_PASSWORD_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 	};
		// case types.FORGOT_PASSWORD_SUCCESS:
		// 	return {
		// 		...state,
		// 		userData: null,
		// 		loading:false
		// 	};
		// case types.FORGOT_PASSWORD_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};
			
		
		// case types.UPDATEPROFILE_REQUEST:
		// return {
		// 	...state,
		// 	loading: true,
		// }	
		// case types.UPDATEPROFILE_SUCCESS:
		// 	return {
		// 		...state,
		// 		userData: action.payload,
		// 		loading:false,
		// 	};
		// case types.UPDATEPROFILE_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};

		// 	case types.UPDATE_USER_LANGUAGE_SUCCESS:
		// 	return {
		// 		...state,
		// 		// userData: action.payload,
		// 		loading:false,
		// 	};
		// case types.UPDATE_USER_LANGUAGE_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};

		// case types.CONTACTUS_REQUEST:
		// 	return {
		// 		...state,
		// 		loading: true,
		// 	};
		// case types.CONTACTUS_SUCCESS:
		// 	return {
		// 		...state, 
		// 		contactUsList: action.payload,
		// 		loading:false,
		// 	};
		// case types.CONTACTUS_ERROR:
		// 	return {
		// 		...state,
		// 		contactUsList:{},
		// 		message: action.payload,
		// 		loading: false,
		// 		isError: true,
		// 	};

		// case types.MYPURCHASE_REQUEST:
		// 		return {
		// 			...state,
		// 			loading: true,
		// 			isRefresh: true,
		// 		};
		// case types.MYPURCHASE_SUCCESS:
		// 		return {
		// 			...state, 
		// 			myPurchaseList: action.payload,
		// 			loading:false,
		// 			isRefresh: false,
		// 		};
		// case types.MYPURCHASE_ERROR:
		// 		return {
		// 			...state,
		// 			myPurchaseList:{},
		// 			message: action.payload,
		// 			loading: false,
		// 			isError: true,
		// 			isRefresh: false,
		// 		};		
		// 		// web login Check

		// 		case types.CHECK_LOGIN_REQUEST:
		// 			return {
		// 				...state,
		// 				loading:true,
					
		// 			};
		// 		case types.CHECK_LOGIN_SUCCESS:
		// 			return {
		// 				...state,
		// 				loading:false,
		// 				webLoginCheck:action.payload,
		// 			};
		// 		case types.CHECK_LOGIN_ERROR:
		// 			return {
		// 				...state,
		// 				loading:false,
		
		// 			};
		// 		// web login end
				

		// case types.MYCERTIFICATE_REQUEST:
		// 		return {
		// 			...state,
		// 			loading: true,
		// 		};
		// case types.MYCERTIFICATE_SUCCESS:
		// 		return {
		// 			...state, 
		// 			myCertificateList: action.payload,
		// 			loading:false,
		// 		};
		// case types.MYCERTIFICATE_ERROR:
		// 		return {
		// 			...state,
		// 			myCertificateList:{},
		// 			message: action.payload,
		// 			loading: false,
		// 			isError: true,
		// 		};
		// case types.CLASSESDATA_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 	};
		// case types.CLASSESDATA_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading:false,
        //         classesData:action.payload
		// 	};
		// case types.CLASSESDATA_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};

		
			
		// 	case types.STREAMSDATA_REQUEST:
		// 	return {
		// 		...state,
		// 		loading:true,
		// 	};
		// case types.STREAMSDATA_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading:false,
        //         streamsData:action.payload
		// 	};
		// case types.STREAMSDATA_ERROR:
		// 	return {
		// 		...state,
		// 		loading:false
		// 	};
		default:
			return state
	}
};

export default userReducer
