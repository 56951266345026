import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

const MainContainer = lazy(() =>
  import("../../src/views/MainContainer/MainContainer")
);
const Signin = lazy(() => import("../views/auth/Login/Login"));

function App() {
  const authToken = localStorage.getItem("accessToken");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, 1000); // Delay the loader by 500 milliseconds (adjust as needed)

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  return (
    <Suspense
      fallback={
        showLoader ? (
          <div className="d-flex align-items-center justify-content-center vh-100">
            <PulseLoader size={25} color="#0050ef" />
          </div>
        ) : null
      }
    >
      <BrowserRouter>
        <Routes>
          {/* Redirect from root path to appropriate route */}
          <Route
            path="/"
            element={
              authToken ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/signin" />
              )
            }
          />
          {/* If the user is authenticated, navigate to the main container */}
          <Route
            path="/*"
            element={authToken ? <MainContainer /> : <Navigate to="/signin" />}
          />
          {/* Redirect authenticated users away from the signin page */}
          <Route
            path="/signin"
            element={authToken ? <Navigate to="/dashboard" /> : <Signin />}
          />
          {/* Default Redirect */}
          <Route
            path="*"
            element={<Navigate to={authToken ? "/dashboard" : "/signin"} />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
