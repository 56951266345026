import HTTP from "./http";

class APIList {
  constructor() {}
  userAuthLogin(params) {
    return HTTP.POST("api/login", params);
  }
  getProfileData() {
    return HTTP.GET("api/v1/user/profile");
  }

  getCourseList(params) {
    return HTTP.GET("api/v1/courses", params);
  }
  createCourse(params) {
    return HTTP.POST("api/v1/courses", params);
  }

  getCourseSubjectLesson(uuid){
    return HTTP.GET(`api/v1/all-courses/${uuid}`)
  }

  updateCourseSetting(params, courseId) {
    return HTTP.PUT(`api/v1/courses/${courseId}/settings`, params);
  }

  getSubjectList(params) {
    return HTTP.GET("api/v1/admin/subjects", params);
  }
  createSubject(params) {
    return HTTP.POST("api/v1/admin/subjects", params);
  }

  getSubjectLesson(subjectid) {
    return HTTP.GET(`api/v1/admin/subjects/${subjectid}`);
  }

  getLessonList(params) {
    return HTTP.GET("api/v1/lessons", params);
  }

  createLesson(params) {
    return HTTP.POST("api/v1/lessons", params);
  }
  updateLessonSetting(params, uuid) {
    return HTTP.PUT(`api/v1/lessons/${uuid}/settings`, params,);
  }

  lessonAssignToCourse(params,uuid){
    return HTTP.PUT(`api/v1/lessons/${uuid}/assign-courses`,params)
  }

  getAllUser(){
    return HTTP.GET("api/v1/user/all-users")
  }

  assignCourseToStudent(params){
    return HTTP.POST("api/v1/admin/assign-course-to-user",params)
  }
}

export default new APIList();
