export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const SUBJECT_LIST_SUCCESS = "SUBJECT_LIST_SUCCESS";
export const SUBJECT_LIST_REFRESH_REQUEST = "SUBJECT_LIST_REFRESH_REQUEST";
export const SUBJECT_LIST_ERROR = "SUBJECT_LIST_ERROR";
export const SUBJECT_LIST_REQUEST = "SUBJECT_LIST_REQUEST";

export const CREATE_SUBJECT_REQUEST = "CREATE_SUBJECT_REQUEST";
export const CREATE_SUBJECT_SUCCESS = "CREATE_SUBJECT_SUCCESS";
export const CREATE_SUBJECT_ERROR = "CREATE_SUBJECT_ERROR";

export const SUBJECT_LESSON_LIST_REQUEST = "SUBJECT_LESSON_LIST_REQUEST";
export const SUBJECT_LESSON_LIST_REFRESH_REQUEST =
  "SUBJECT_LESSON_LIST_REFRESH_REQUEST";
export const SUBJECT_LESSON_LIST_SUCCESS = "SUBJECT_LESSON_LIST_SUCCESS";
export const SUBJECT_LESSON_LIST_ERROR = "SUBJECT_LESSON_LIST_ERROR";
